<template>
  <div class="card-panel">
    <div class="card-header">卡系管理寄存清单</div>
    <!-- <div class="card-action">
      <span>卡系筛选</span>
      <Select v-model="query.kxlx_id" style="width: 200px" clearable>
        <Option v-for="item in cardList" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
      <span>会员类型</span>
      <Select v-model="query.hylx" style="width: 200px" clearable>
        <Option value="卡系会员">卡系会员</Option>
        <Option value="普通会员">普通会员</Option>
        <Option value="全部会员">全部会员</Option>
      </Select>
      <Button type="info" @click="handleQuery">查询</Button>
    </div> -->
    <div class="card-table">
      <div style="overflow: auto;">
        <table class="table">
          <thead>
            <tr>
              <th v-for="(item, index) in dataHeads" :key="index">{{ item }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataList" :key="'tr' + index">
              <td v-for="(value, i) in item" :key="'tr' + index + 'td' + i">
                <span
                  v-if="i >= 5 && i % 2 === 1"
                  class="status-dot"
                  :style="{ backgroundColor: value === 1 ? 'gray' : (value === 2 ? 'red' : '#fdad00') }"
                ></span>
                <span v-else>{{ value }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <span class="records">共 {{ dataTotal }} 条记录</span>
      </div>
      <div class="page-nations-wrapper">
        <div class="page-nations">
          <Page
            :total="dataTotal"
            :current="dataCurrentPage"
            :page-size="query.size"
            @on-change="changeDataPage"
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cardService from '@/services/cardService';

export default {
	data() {
		return {
			dataHeads: [],
			dataList: [],
			dataTotal: 0,
			dataCurrentPage: 1,
			query: {
				page: 1,
				size: 10,
			},
		};
	},
	mounted() {
		this.getCardManagementList();
	},
	methods: {
		getCardManagementList() {
			cardService.cardManagementList(this.query).then((data) => {
				this.dataList = data.data.list;
				this.dataHeads = data.head;
				this.dataTotal = data.data.row_size;
				this.dataCurrentPage = data.data.cur_page;
			});
		},
		changeDataPage(page) {
			this.query.page = page;
			this.getCardManagementList();
		},
	},
};
</script>

<style lang="less" scoped>
.card-header {
  font-size: 16px;
  height: 48px;
  line-height: 48px;
}
.card-action {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.card-panel {
  background-color: #fff;
  padding: 0 20px;
  table {
    text-align: center;
  }
  th {
    height: 38px;
    background-color: #fbfbfb;
    border-bottom: #fdad00 solid 1px;
    padding: 8px 12px;
    min-width: 100px;
    vertical-align: middle;
  }
  td {
    border-bottom: 1px solid #e8eaec;
    padding: 8px 12px;
    vertical-align: middle;
  }
  .status-dot {
    display: inline-block;
    height: 14px;
    width: 14px;
    border-radius: 14px;
    vertical-align: middle;
  }
}
</style>
